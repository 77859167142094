import fetchJsonp from 'fetch-jsonp';
import { getDistance } from "geolib";
import * as json from "./json.json";

export async function getVehicles(center, targetDistance, hidePrius) {
	const targetUrl =
		"https://a4ueegujgnsqnzacyhiehf5egy0empyq.lambda-url.us-east-1.on.aws";

	let data = await fetch(targetUrl);

	data = await data.json();
	// let data = json;

	// Remove duplicates
	if (!data.Vehicules) {
		return [];
	}
	data = data.Vehicules.filter((vehicle, index) => {
		// Filter by model if needed
		if (hidePrius && vehicle.ModelName === "Prius C") {
			return false;
		}
		for (let i = index + 1; i < data.Vehicules.length; i++) {
			if (vehicle.Id === data.Vehicules[i].Id) {
				return false;
			}
		}
		return true;
	});

	data = data.map(function (elem) {
		const coordsVehicle = {
			latitude: elem.Position.Lat,
			longitude: elem.Position.Lon,
		};
		const coordsUser = { latitude: center.lat(), longitude: center.lng() };

		var distance = getDistance(coordsVehicle, coordsUser);
		var good = distance <= targetDistance ? true : false;
		return {
			distance: Math.round(distance),
			name: elem.Name,
			coords: coordsVehicle,
			good: good,
			data: elem,
			energy: elem.EnergyLevel,
			id: elem.Id,
		};
	});

	// console.log(data);

	return Object.values(data);
}
