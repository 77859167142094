<script lang="ts">
    // import Map from './Map.svelte';
    import { getCoords } from "./helpers/Geocoder";
    import { getVehicles } from "./helpers/Communauto";

    import Map from "./Map.svelte";
    import Listing from "./Listing.svelte";
    import { onMount, onDestroy } from "svelte";

    export let distance: number = 700;
    export let address: string;
    export let language: string;
    export let hidePrius: boolean = false;

    let firstLoad: boolean = true;
    let syncing: boolean = false;
    let center;
    let vehicles: any[] = [];
    let timerDuration = 8;
    let timer: number = timerDuration;
    let interval;
    let sound;

    $: vehiclesFiltered = filterVehicles(vehicles);
    $: almostCar =
        vehiclesFiltered.length > 0 &&
        vehiclesFiltered.filter((vehicle) => {
            return vehicle.good;
        }).length === 0;

    onMount(async () => {
        center = await getCoords(address);
        vehicles = await getVehicles(center, distance, hidePrius);

        firstLoad = false;
        interval = setInterval(async function () {
            if (timer > 0) timer--;
            if (timer == 0) syncVehicles();
        }, 1000);
    });

    onDestroy(() => {
        clearInterval(interval);
    });

    function filterVehicles(vehicles) {
        if (vehicles.some(v => v.good)) {
            // displayNotification();
            if (sound) {
                sound.play();
            } else {
                setTimeout(function () {
                    if (sound) {
                        sound.play();
                    }
                }, 100);
            }
        }
        
        const filteredVehicles = vehicles
            .filter(function (vehicle) {
                return vehicle.good || vehicle.distance <= distance * 1.2;
            })
            .sort(function (a, b): number {
                return a.distance - b.distance;
            });
        console.log(filteredVehicles);
        return filteredVehicles;
    }

    async function syncVehicles() {
        syncing = true;
        vehicles = await getVehicles(center, distance, hidePrius);
        syncing = false;
        timer = timerDuration;
    }

    function displayNotification() {
        if (Notification.permission == "granted") {
            navigator.serviceWorker.getRegistration().then(function (reg) {
                reg.showNotification("A car is available");
            });
        }
    }
</script>

<div>
    {#if firstLoad}
        <p>{language == 'FR' ? 'Chargement...' : 'Loading...'}</p>
    {:else}
        <div class="sm:flex mb-3">
            {#if syncing}
                <p>{language == 'FR' ? 'Synchronisation...' : 'Syncing...'}</p>
            {:else}
                <div class="flex-1">
                    <label
                        class="block text-gray-700 text-sm mb-2"
                        for="progress"
                    >
                        {language == 'FR' ? 'Prochaine synchronisation dans : ' : 'Next sync in : '}
                        {timer}sc
                    </label>
                    <progress max={timerDuration} value={timer} />
                </div>
                <div class="flex-1">
                    <button
                        on:click={syncVehicles}
                        class="bg-commugreen hover:bg-commugreen-dark text-white
                        font-bold py-2 px-4 rounded focus:outline-none
                        focus:shadow-outline"
                        type="button"
                    >
                        {language == 'FR' ? 'Synchroniser maintenant' : 'Sync now'}
                    </button>
                </div>
            {/if}

        </div>
        {#if almostCar}
            <p class="text-m mb-3">
                {language == 'FR' ? "Il y'a une voiture juste en-dehors de votre zone !" : "There's a car just outside your zone !"}
            </p>
        {/if}
        <Map {language} {center} vehicles={vehicles} {distance} />
        <div class="text-lg	font-bold mt-3 mb-3">
            {#if vehiclesFiltered.length}
                {language == 'FR' ? "Voitures autour de vous : " : "Cars around you : "}
            {:else}
                {language == 'FR' ? "Il n'y a aucune voiture autour de vous" : "There are no cars around you"}
            {/if}
        </div>
        <Listing {language} vehicles={vehiclesFiltered} />
        <audio src="ding.mp3" bind:this={sound} />
    {/if}
</div>
