<script lang="ts">
	import { text } from "svelte/internal";
	import Form from "./Form.svelte";
	import Cars from "./Cars.svelte";
	import { onMount} from "svelte";

	let mapDisplayed: boolean = false;
	let address: string = "";
	let distance: number = 500;
	let hidePrius: boolean = false;
	let language: string = "FR";
	let storageDistance : number = 0;

	function switchLanguage() {
		language == "FR" ? (language = "EN") : (language = "FR");
	}
	function displayMap(event) {
		mapDisplayed = !mapDisplayed;
		distance = event.detail.distance;
		address = event.detail.address;
		hidePrius = event.detail.hidePrius;

		localStorage.setItem("address", address);
		localStorage.setItem("distance", distance);
		localStorage.setItem("hidePrius", hidePrius);
	}

	onMount(() => {
        address = localStorage.getItem("address");
		storageDistance = +localStorage.getItem("distance");
		distance = storageDistance ? storageDistance : distance;
    });
</script>

<main class="m-5 sm:flex sm:flex-col sm:justify-center sm:items-center">
		<div class="flow-root">
			<h1 class="text-commugreen float-left text-xl mb-5">Flex Finder</h1>	
			<div on:click={switchLanguage} class=" float-right text-m mb-5">
				{language == 'FR' ? 'EN' : 'FR'}
			</div>
		</div>

		
	

	{#if mapDisplayed}
		<div>
			<button
				on:click={() => {
					mapDisplayed = !mapDisplayed;
				}}
				class="bg-commugreen hover:bg-commugreen-dark text-gray-800 py-2
				px-4 rounded inline-flex items-center mb-3"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					width="24"
					height="24"
				>
					<path
						class="heroicon-ui"
						d="M5.41 11H21a1 1 0 0 1 0 2H5.41l5.3 5.3a1 1 0 0 1-1.42
						1.4l-7-7a1 1 0 0 1 0-1.4l7-7a1 1 0 0 1 1.42 1.4L5.4 11z"
					/>
				</svg>
				<span>
					{language == 'FR' ? "Changer l'adresse" : 'Change address'}
				</span>
			</button>
			<Cars {language} {address} {distance} {hidePrius}/>

		</div>
	{:else}
		<Form {language} {address} {distance} on:message={displayMap} />
	{/if}

	<p class="mt-5 text-sm">
		{language == 'FR' ? "Développé et créé par Arthur Joliveau" : 'Developed and created by Arthur Joliveau'}		
	</p>	
	<p>
		
	</p>
</main>
