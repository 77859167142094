<script lang="ts">    
    export let vehicles: any[];
    export let language: string;
</script>

<div class="mb-60">
    <table class="table-auto w-full text-md bg-white shadow-md rounded mb-4">
        <thead>
            <tr class="border-b">
                <th class="hidden sm:table-cell text-left p-3 sm:px-5">{language == "FR" ? "Dans la zone" : "In range"}</th>
                <th class="text-left p-3 sm:px-5">{language == "FR" ? "Modèle" : "Model"}</th>
                <th class="text-left p-3 sm:px-5">{language == "FR" ? "ID" : "ID"}</th>
                <th class="text-left p-3 sm:px-5">{language == "FR" ? "Distance" : "Distance"}</th>
                <th class="text-left p-3 sm:px-5">{language == "FR" ? "Essence" : "Fuel"}</th>
            </tr>
        </thead>
        <tbody>
            {#each vehicles as vehicle, i (vehicle)}
            <tr class="border-b hover:bg-orange-100" class:bg-gray-100={i%2 == 1}>            
                <td class="hidden sm:table-cell p-3 sm:px-5 flex justify-start">
                    <input disabled class="form-checkbox h-5 w-5 text-gray-600" type="checkbox" bind:checked={vehicle.good}/>
                </td>
                <td class="p-3 sm:px-5">{vehicle.data.ModelName}</td>
                <td class="p-3 sm:px-5">{vehicle.name}</td>
                <td class="p-3 sm:px-5">{vehicle.distance}m</td>
                <td class="p-3 sm:px-5">{vehicle.energy}%</td>
            </tr>               
            {/each}
        </tbody>
    </table>
</div>